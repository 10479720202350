.half-n-half-blockquote {
	.bg {
		position: absolute;
			top: 0;

		@include offsetBlock($container-xl, 10, right);
		height: 100%;

		background-color: $color-background-secondary;
		pointer-events: none;
		z-index: -2;

		@include media-breakpoint-down(lg) {
			@include offsetBlock($container-lg, 10, right);
		}

		@include media-breakpoint-down(md) {
			@include offsetBlock($container-md, 10, right);
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.row {
		align-items: center;

		@include media-breakpoint-down(md) {
			align-items: center;
		}
	}

	.content {
		padding-top: 70px;
		padding-bottom: 70px;

		@include media-breakpoint-down(md) {
			padding: 50px $grid-gutter-width/2;
			margin-left: 30px;
		}

		@include media-breakpoint-down(sm) {
			background-color: $color-background-secondary;
			padding: 50px $grid-gutter-width/2;
			margin: 0 $grid-gutter-width/2;
		}

		@include media-breakpoint-down(xs) {
			padding: $marginHeightMobile/2 $grid-gutter-width/2;
			margin: 0 $grid-gutter-width/2;
		}
	}

	.blockquote {
		blockquote {
			display: block;
			padding: 50px 40px;
			text-align: center;
			margin-bottom: 0px;

			@include media-breakpoint-down(md) {
				padding: 40px 30px;
			}

			@include media-breakpoint-down(sm) {
				margin-top: 0px;
			}

			@include media-breakpoint-down(xs) {
				padding: 30px $grid-gutter-width/2;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.inverted-mobile {
			flex-direction: column-reverse;
		}
	}

	@include media-breakpoint-down(xs) {
		margin: $marginHeightMobile 0;
	}
}

.include-newsletter {
	background-color: $color-accent-primary_hover;

	color: $color-text-secondary;
	padding: 20px 0 25px 0;
}

.info-newsletter {
	display: flex;
	flex-direction: column;

	p {
		line-height: 1.3;
	}
}

.form-newsletter {
	.message {
		height: 0;
		overflow: hidden;
	}

	form {
		width: 100%;

		display: flex;
		overflow: hidden;
		
		label {
			display: none;
		}

		input[type=email] {
			appearance: none;
			-webkit-appearance: none;

			width: 100%;
			padding: 10px 15px;

			background-color: transparent;
			outline: none;
			border: 1px solid $white;
			border-color: $white;
			color: $white;
			font-weight: fs(14px);
			font-family: $font-family-text;

			&:focus {
				outline: none;
			}

			&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				color: rgba(255, 255, 255, 0.6);
			}

			&:-ms-input-placeholder {
				color: rgba(255, 255, 255, 0.6);
			}

			&::-ms-input-placeholder {
				color: rgba(255, 255, 255, 0.6);
			}
		}

		button[type=submit] {
			margin-top: 0;
			margin-left: -2px;
			border-left: 1px solid $white;
			cursor: pointer;

			&:focus {
				outline: none;
			}
		}

		@include media-breakpoint-down(md) {
			margin-top: $grid-gutter-width/2;
		}

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		
			button[type=submit] {
				margin-top: 10px;
				width: 100%;
				margin-left: 0;
			}
		}

		@include media-breakpoint-down(xs) {
			button[type=submit] {
				padding: 10px $grid-gutter-width/2;
				line-height: 1.8;
				margin-top: 10px;
			}
		}
	}
}
.full-width {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	.block {
		padding-top: 60px;
		padding-bottom: 60px;

		text-align: center;

		@include media-breakpoint-down(sm) {
			padding: 50px $grid-gutter-width/2;
		}

		@include media-breakpoint-down(xs) {
			padding: $marginHeightMobile $grid-gutter-width/2;
		}
	}

	blockquote {
		margin: 0;
	}

	@include media-breakpoint-down(sm) {
		margin: $marginHeightMobile $grid-gutter-width/2;
	}

	@include media-breakpoint-down(xs) {
		h1 {
			margin-bottom: $marginHeightMobile/2;
		}

		p {
			line-height: 1.5;
		}

		[class^=button] {
			margin-top: $marginHeightMobile/2;
		}
	}
}
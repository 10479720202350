%title {
	font-family: $font-family-title;
	font-weight: $font-weight-bold;
	line-height: (40/30);
	margin: 0 0 0.1em 0;
	letter-spacing: 0;
}

%title1 {
	@extend %title;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	margin: 0 0 1em 0;
	letter-spacing: 1.2px;
	
	@include responsive-fontsizes((
		xl: $font-size-title1-lg,
		lg: $font-size-title1-md,
		md: $font-size-title1-sm,
		sm: $font-size-title1-xs,
	));
}

%title2 {
	@extend %title;
	font-weight: $font-weight-normal;
	letter-spacing: 1.2px;
	line-height: 1;
	text-transform: none;
	margin: 0 0 0.5em 0;

	.inverted-color & {
		color: $white;
	}

	@include responsive-fontsizes((
		xl: $font-size-title2-lg,
		lg: $font-size-title2-md,
		md: $font-size-title2-sm,
		sm: $font-size-title2-xs,
	));
}

%title3 {
	@extend %title;
	line-height: (30 / 18);
	margin: 0 0 0.8em 0;

	@include responsive-fontsizes((
		xl: $font-size-title3-lg,
		lg: $font-size-title3-md,
		md: $font-size-title3-sm,
		sm: $font-size-title3-xs,
	));
}

%title4 {
	@extend %title;
	line-height: (30 / 16);
	margin: 0 0 0.8em 0;

	@include responsive-fontsizes((
		xl: $font-size-title4-lg,
		lg: $font-size-title4-md,
		md: $font-size-title4-sm,
		sm: $font-size-title4-xs,
	));
}

%text {
	font-family: $font-family-text;
	font-weight: $font-weight-light;
	line-height: 1.8;

	@include responsive-fontsizes((
		xl: $font-size-text-xl,
		lg: $font-size-text-md,
		md: $font-size-text-sm,
		sm: $font-size-text-xs,
	));
}

%blockquote {
	@extend %title2;
	font-weight: $font-weight-light;
	line-height: 1.5;

	@include responsive-fontsizes((
		xl: $font-size-blockquote-lg,
		lg: $font-size-blockquote-md,
		md: $font-size-blockquote-sm,
		sm: $font-size-blockquote-xs,
	));
}

%unordered_list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
		line-height: 1.5;

		&:before {
			position: absolute;
				top: calc(1/2 * 1.4em);
				left: 0;

			transform: translate(0, -50%);

			content: ' ';
			display: block;

			width: 10px;
			height: 10px;
			border-radius: 100%;
			background-color: $color-accent-primary;
		}

		ul {
			margin-top: 15px;
			
			li {
				margin-bottom: 15px;

				&:before {
					width: 10px;
					height: 10px;
					background-color: $white;
					border: 2px solid $color-accent-primary;
				}
			}
		}
	}
}

* {
	-webkit-font-smoothing: antialiased;
}

blockquote, blockquote p {
	@extend %blockquote;
}

body {
	@extend %text;
	line-height: 1.4;
	color: $color-text-primary;
}

h1 {
	@extend %title1;
	
	section &, article & {
		@extend %title2;
		
		section &, article & {
			@extend %title3;

			section &, article & {
				@extend %title4;
			}
		}
    }
}

h2 {
	@extend %title2;
	
	section &, article & {
		@extend %title3;
		
		section &, article & {
			@extend %title4;
		}
	}
}

h3 {
	@extend %title3;
	
	section &, article & {
		@extend %title4;
	}
}

p {
	@extend %text;
	margin-top: 0;
	margin-bottom: 1em;

	&:last-child() {
		margin-bottom: 0;
	}

	&.small {
		font-size: fs(14px);
	}
}

.inverted-color {
	color: $white;
}

a:not([class]) {
	color: $color-accent-primary;
	text-decoration: none;
	font-style: normal;
	font-weight: $font-weight-bold;
	transition: color 0.3s;

	&:hover {
		text-decoration: none;
		color: lighten($color: $color-accent-primary, $amount: 30%);
	}
}

em {
	font-style: italic;
}

strong {
	font-weight: $font-weight-bold;
}

ul {
	@extend %unordered_list;
}
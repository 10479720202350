.footer {
	background: $gray8;
	color: $white;

	.section-wrapper {
		border-top: 1px solid rgba($white, 0.2);
		padding: $grid-gutter-width $grid-gutter-width/2;
		
		@include media-breakpoint-down(xs) {
			padding: 20px;
		}
	}

	.peripharm-info {
		display: flex;
		line-height: 1.5;

		[class^=icon] {
			font-size: 32px;
			color: $blue77;
			margin-right: 20px;
			text-decoration: none;
	
			&:before {
				margin: 0;
				width: auto;
			}
		}
		
		@include media-breakpoint-down(sm) {
			padding-bottom: $grid-gutter-width;

			&:last-of-type() {
				padding-bottom: 0;
			}
		}
	}

	.legal {
		font-size: 14px;
		text-align: center;
		margin-top: 8px;
	}

	.terms {
		width: 100%;
	
		.link_ctn {
			text-align: center;
			margin: 0 auto;
		}
	}

	a {
		color: $blue77;
		font-weight: $font-weight-light;
	}
}
.text-block {
	margin: $marginHeight 0;
	
	.text-block-content {
		background-color: $color-background-secondary;
		padding: $marginHeight;
		text-align: center;
		
		@include media-breakpoint-down(sm) {
			padding: $grid-gutter-width $grid-gutter-width/2;
		}

		@include media-breakpoint-down(xs) {
			padding: $grid-gutter-width $grid-gutter-width/2;
		}
	}

	@include media-breakpoint-down(sm) {
		margin: $marginHeightMobile 0;
	}
}
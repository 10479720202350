
.header {
	color: $color-text-primary;
	
	.row {
		height: 1px;
		min-height: 350px;

		@include media-breakpoint-down(lg) {
			min-height: 300px;
		}

		@include media-breakpoint-down(md) {
			min-height: 250px;
		}
	}
}
%hero-banner {
	background-position: center center;
	background-size: cover;
	color: $color-accent-primary_hover;

	&.inverted-color {
		color: $color-text-secondary;
	}

	.container, .row {
		height: 100%;
	}

	.row {
		padding-top: $menuTotalHeight;
		align-items: center;
		justify-content: center;
		text-align: center;

		@include media-breakpoint-down(xs) {
			padding-top: 80px;
			padding-bottom: 30px;
		}
	}
	
	.content {
		h1, h2, h3 {
			margin: 0;
		}

		.date {
			font-weight: $font-weight-medium;
		}

		img {
			width: 98px;
			fill: $white;
		}

		@include media-breakpoint-down(sm) {
			padding: 0 100px;
		}

		@include media-breakpoint-down(xs) {
			padding: 0 30px;
		}
	}
}

.hero-banner {
	@extend %hero-banner;

	&.hasVideo {
		position: relative;
		height: 74vh;

		@include at-least(1920px) {
			overflow: hidden;
		}
		
		.row {
			justify-content: flex-start;
			text-align: left;
		}

		video {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover; object-position: center;';
			object-position: center;
		}

		@include media-breakpoint-down(lg) {
			height: 60vh;
		}

		@include media-breakpoint-down(md) {
			height: 40vh;
		}

		@include media-breakpoint-down(sm) {
			height: 400px;
			
			.content {
				padding: 0 100px 0 $grid-gutter-width/2;
			}
		}

		@include media-breakpoint-down(xs) {
			height: 300px;
		}
	}
}
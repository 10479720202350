.expendable {
	.expendable-title {
		cursor: pointer;
		
		i {
			font-size: fs(25px);
			position: absolute;
				right: 25px;
			line-height: 1;

			&:before {
				width: auto;
				margin: 0;
				transition: transform 0.3s;
			}
		}

		&.open {
			i:before {
				transform: rotate(180deg);
			}
		}
	}
	
	@include media-breakpoint-down(sm) {
		.expendable-content {
			height: 0;
			overflow: hidden;
		}

		margin: 0px;
	}
}
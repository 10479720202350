
%base_button {
	display: inline-block;
	margin-top: 25px;

	outline: none;
	border: none;
	text-decoration: none;

	padding: 20px 40px 18px;
	background: $color-background-darkest;

	@extend %title;
	margin-bottom: 0;
	font-size: fs(12px);
	line-height: 100%;
	color: $color-text-secondary;
	text-transform: uppercase;
	text-align: center;
	font-weight: $font-weight-medium;
	letter-spacing: 1.5px;

	transition: background 0.3s, color 0.3s;

	&:hover, &:focus, &:active {
		color: $color-text-secondary;
		text-decoration: none;
		background: darken($color-background-darkest, 10%);
	}

	@include media-breakpoint-down(sm) {
		min-width: 0;
		padding: 20px 20px;
		font-size: fs(12px);
	}
}

%button-inline {
	padding: 0;
	background-color: transparent;
	color: $color-accent-primary_hover;
	margin-top: 0;
	display: flex;
	align-items: center;
	line-height: 1.6;

	i {
		font-size: fs(10px);
		margin-left: 10px;
		transition: margin 0.3s;
	}

	&:hover, &:focus {
		background-color: transparent;
		text-decoration: underline;
		text-underline-position: below;
		
		i {
			margin-left: 5px;
		}
	}
}

%button-rounded {
	position: relative;
	width: 55px;
	height: 55px;
	border-radius: 100%;
	padding: 0;
	margin-top: 0;
	transform: scale(1, 1);
	transition: transform 0.3s, background 0.3s;

	&:after {
		content: '\e800';
		@include fontello();
		position: absolute;
			top: 0;
			left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		width: 100%;
		height: 100%;
		font-size: fs(30px);
	}

	&:hover {
		transform: scale(1.05, 1.05);
		background-color: $color-accent-primary_hover;
	}
}

[class^=button] {
	@extend %base_button;

	&[class*=-inverted] {
		color: $color-background-darkest;
		background: $white;

		&:hover, &:active {
			background: mix($black, $white, 10%);
		}

		&:focus {
			outline: none;
		}
	}

	&[class*=-inline] {
		@extend %button-inline;
	}

	&[class*=-rounded] {
		@extend %button-rounded;
	}
}
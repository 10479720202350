.contact-block {
	position: relative;
	height: 70vh;

	overflow: hidden;
	background: $color-background-secondary;

	.map-ctn {
		position: absolute;
		width: calc(6 / 12 * 100% - #{$grid-gutter-width/2});
		height: 100%;

		&:after {
			content: '';
			position: relative;
			display: block;
			padding-top: 100%;
		}

		@include media-breakpoint-down(md) {
			position: relative;
			width: 100%;
			height: 50vh;
		}
	}

	#map {
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.address-container {
		height: 100%;

		.row {
			height: 100%;
			align-items: center;
		}
	}

	.address {
		p {
			line-height: 1.3;
		}
	}

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column-reverse;
		height: auto;

		.address {
			padding: 60px 0;
		}
	}

	@include media-breakpoint-down(sm) {
		margin: $marginHeightMobile $grid-gutter-width/2;
		background: none;
		
		.address-container {
			margin-bottom: $marginHeightMobile;
			background: $color-background-secondary;
		}

		.address {
			padding: $grid-gutter-width $grid-gutter-width/2;
		}
	}
}
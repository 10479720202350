.main-menu {
	position: absolute;
	z-index: 100;

	display: flex;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	height: $menuHeight;

	margin-top: 20px;

	&:after {
		content: '';
		position: absolute;
			bottom: 0;
			left: $grid-gutter-width/2;
		width: calc(100% - #{$grid-gutter-width});
		height: 1.5px;
		background-color: rgba($white, 0.3);
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 20px;
		width: 200px;

		svg {
			max-width: 100%;
		}

		@include media-breakpoint-down(sm) {
			width: 150px;
		}
	}

	.btn-menu {
		align-self: center;

		position: relative;
		width: 30px;
		height: 24px;
		margin-right: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		span, &:after, &:before {
			flex: 0 0 100%;
			display: inline-block;
			width: 100%;
			height: 3px;
			background: $white;
		}

		&:after, &:before {
			content: '';
		}
	}

	.btn-close-button {
		padding: 20px;
		background: $color-accent-primary;
		color: $color-text-secondary;
		text-decoration: none;
		text-transform: uppercase;
		font-size: fs(12px);
		font-weight: $font-weight-medium;
		letter-spacing: 2.6px;
		text-align: right;
		cursor: pointer;
	}

	.top-menu {
		align-self: stretch;

		@include media-breakpoint-down(md) {
			position: fixed;
			top: 0;
			right: 0;
			max-width: 320px;
			width: 100%;
			height: 100%;
			background: $white;
			overflow-x: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			z-index: 100;
			transform: translate(100%, 0);
			transition: transform 0.3s ease;

			&.opened {
				transform: translate(0, 0);
			}

			ul {
				flex-direction: column;

				li {
					a {
						padding: 20px;
						width: 100%;
						color: $color-text-primary;
					}
				}
			}
		}
	}

	ul {
		position: relative;
		z-index: 2;

		list-style: none;
		padding: 0;
		margin: 0;

		display: flex;
		height: 100%;

		li {
			padding: 0;
			margin: 0;
			display: flex;
			align-items: stretch;

			&:before {
				content: none;
			}

			a {
				position: relative;
				display: flex;
				align-items: center;
				padding: 0 $grid-gutter-width/2 20px;
				
				color: $color-text-secondary;
				text-decoration: none;
				text-transform: uppercase;
				font-size: fs(12px);
				font-weight: $font-weight-medium;
				letter-spacing: 2.6px;

				&:after {
					content: '';
					position: absolute;
						bottom: -1.5px;
						left: $grid-gutter-width/2;
					width: calc(100% - #{$grid-gutter-width});
					height: 4px;
					background-color: $color-accent-primary;
					transform: scale(0, 1);
					transform-origin: left center;
					transition: transform 0.3s;
				}
			}

			&:last-of-type() {
				a {
					padding-right: 0;
				}
			}

			// &:hover {
				
			// }

			&.open {
				a:after {
					transform: scale(1, 1);
				}
			}

			@include media-breakpoint-down(lg) {
				a {
					padding: 0 10px 20px;
				}
			}
		}
	}


	@include media-breakpoint-down(sm) {
		padding: 0 $grid-gutter-width/2; 
	}
}

.news-content {
	margin-top: 0;
	margin-bottom: calc(#{$marginHeight} * 2);

	img {
		margin: 30px 0;
		max-width: 100%;
	}

	@include media-breakpoint-down(sm) {
		margin: 0 0 $marginHeightMobile 0;
	}
}
.title-container {
	text-align: center;
}

.back-container {
	margin: $marginHeight 0;

	.back {
		a[class^=button] {
			i {
				margin-left: 0;
				margin-right: 10px;

				&:before {
					transform: rotate(180deg);
				}
			}

			&:hover {
				i {
					margin-left: 0;
					margin-right: 5px;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		margin: $marginHeightMobile 0 $marginHeightMobile 0;
	}
}

.button {
	margin: 25px;
}

.pdf-outerCtn {
	padding-bottom: 100px;
	.pdf-container {
		width: 100%;
		height: 100%;

		object, iframe {
			min-height: 50vh;
			height: 100%;
			width: 100%;

		}
	}
}

.half-half-team-member {
	margin: $marginHeight 0;

	.row {
		&.reverse {
			flex-direction: row-reverse;

			.half-half-team-member-details {
				margin-right: calc(-2 / 12 * 100%);
				padding-right: calc(3 / 12 * 100% + #{$grid-gutter-width/2});

				margin-left: 0;
				padding-left: calc(1 / 12 * 100% + #{$grid-gutter-width/2});
				
				@include media-breakpoint-down(sm) {
					margin-right: 0;
					padding: $grid-gutter-width $grid-gutter-width/2;
					margin-top: 0px;
				}
				
				@include media-breakpoint-down(xs) {
					padding: $marginHeightMobile/2 $grid-gutter-width/2;
				}
			}
		}
	}

	.half-half-team-member-details {
		display: flex;
		flex-direction: column;
		justify-content: center;

		background-color: $gray97;
		margin-left: calc(-2 / 12 * 100%);
		margin-top: 90px;

		padding: 100px calc(1 / 12 * 100% + #{$grid-gutter-width/2}) 100px calc(3 / 12 * 100% + #{$grid-gutter-width/2});

		&:last-child {
			margin-bottom: 0;
		}

		@include media-breakpoint-down(md) {
			padding-left: calc(2 / 12 * 100% + #{$grid-gutter-width/2});

			padding-top: 40px;
			padding-bottom: 40px;
			margin-top: 60px;
		}

		@include media-breakpoint-down(sm) {
			margin-left: 0;
			padding: $grid-gutter-width $grid-gutter-width/2;
			margin-top: 0px;
		}
		
		@include media-breakpoint-down(xs) {
			padding: $marginHeightMobile/2 $grid-gutter-width/2;
		}

		.half-half-team-member-phone {
			margin-top: $grid-gutter-width/2;
			a {
				font-weight: $font-weight-normal;
			}
		}
	}

	.team-member-details {
		padding-top: 120px;
		padding-bottom: 120px;

		@include at-most(991px) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}

	.half-half-team-member-name {
		@extend %title2;
		margin-bottom: 0;
	}

	.half-half-team-member-role {
		@extend %title3;
		font-weight: $font-weight-light;
		margin-bottom: 20px;

		@include at-most(991px) {
			margin-bottom: 15px;
		}
	}

	.icons {
		i {
			color: $black;
			font-size: fs(30px);
		}
	}

	.photo-wrapper {
		position: relative;
		z-index: 2;
		align-items: center;

		img {
			flex-shrink: 0;
			max-width: 100%;
		}

		@include media-breakpoint-down(sm) {
			img {
				max-width: none;
				width: 100%;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		margin: $marginHeightMobile 0;
	}
}
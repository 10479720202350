@mixin reset-position() {
	position: relative;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
}

form {
	.field {
		position: relative;
		margin: 0 0 $grid-gutter-width/2 0;

		label {
			position: absolute;
			margin: 0;
			transition: all, 0.3s;
		}

		select,
		textarea,
		input[type=text],
		input[type=tel],
		input[type=email],
		input[type=password],
		input[type=number] {
			position: relative;
			padding: 0 20px;
			background: $color-background-dark;
			color: $white;
			font-size: 16px;
			
			width: 100%;
			height: 50px;
			line-height: 50px;
			
			outline: none;
			border: none;
			border: 1px solid $color-input-border;
			border-radius: 0px;
			
			-webkit-appearance: none;

			& ~ label {
				cursor: text;
				pointer-events: none;

				position: absolute;
					top: 0;
					left: 0;

				padding: 0 20px;

				width: 100%;
				height: 50px;
				line-height: 50px;
				
				color: $color-input-placeholder;
				font-size: 16px;
				font-style: normal;
				font-weight: $font-weight-normal;

				.asterix {
					color: $color-accent-primary;
				}

				span {
					transition: opacity 0.2s $easeInOutCubic 0.1s;
					opacity: 1;
				}

				&:after {
					content: attr(data-placeholder);
					position: absolute;
						top: 4px;
						left: 4px;
					
					font-size: 10px;
					letter-spacing: 1px;
					line-height: 8px;
					
					transition: opacity 0.2s $easeInOutCubic, transform 0.2s $easeInOutCubic, color 0.2s;

					opacity: 0;
					transform: translate(0, 4px);
				}
			}

			& ~ label.visible {
				pointer-events: none;

				span {
					opacity: 0;
					transition: opacity 0.1s $easeInOutCubic;
				}

				&:after {
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.2s $easeInOutCubic 0.1s, transform 0.2s $easeInOutCubic 0.1s;
				}
			}

			&:focus ~ label.visible:after {
				// color: $color-input-accent;
			}

			&.error {
				color: $color-input-error;
				border-color: $color-input-error-border;

				& ~ label {
					color: $color-input-error;
				}
			}
		}

		select {
			& ~ label {
				pointer-events: none;

				span {
					opacity: 0;
					transition: opacity 0.1s $easeInOutCubic;
				}

				&:after {
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.2s $easeInOutCubic 0.1s, transform 0.2s $easeInOutCubic 0.1s;
				}
			}
		}

		textarea {
			padding: 10px 15px;
			border: 1px solid $color-input-border;
			width: 100%;
			height: 145px;
			line-height: 20px;
			padding-top: 15px;

			& ~ label {
				padding-top: 5px;
				line-height: inherit;
				height: auto;
			}
		}

		&.select {
			&:after {
				pointer-events: none;
				position: absolute;
				top: 50%;
				right: 10px;
				content: '\f078';
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				transform: translate(0, -50%);
			}
		}

		input[type=checkbox] {
			//en opacité 0 plutot que display none, sinon validation browser affiche pas
			position: absolute;
			opacity: 0;

			& ~ label {
				cursor: pointer;
				@include reset-position();
				display: block;
				padding-left: 50px;

				&:before {
					content: ' ';
					position: absolute;
						top: 8px;
						left: 0;

					width: 30px;
					height: 30px;
					border: 1px solid $color-input-border;
				}

				&:after {
					display: none;
					content: 'L';
					position: absolute;
						top: 6px;
						left: 10px;
					font-size: 20px;
					color: $color-text-primary;
					// text-shadow: 0px 0px 2px $color-input;

					transform: rotate(45deg) scale(-1, 1);
				}
			}

			&:checked ~ label:after {
				display: block;
			}
		}
	}
}
.news-block {
	margin: $marginHeight 0;

	.news {
		padding-bottom: 30px;
		margin-top: $grid-gutter-width;
		border-bottom: 1px solid $color-background-secondary;

		&:first-of-type() {
			margin-top: 0;
		}

		[class^=col] {
			padding: 0 $grid-gutter-width/2;
		}

		.date {
			margin-bottom: 20px;
			font-weight: $font-weight-normal;

			@include media-breakpoint-down(sm) {
				margin-bottom: 0px;
				font-size: fs(14px);
			}
		}

		.title {
			display: inline-block;
			line-height: 1.2;
			font-size: $font-size-title3-xl;
			margin-bottom: 20px;
			color: $color-text-primary;
			
			&:hover, &:focus {
				color: $color-text-primary;
				text-decoration: none;
			}

			@include responsive-fontsizes((
				xl: $font-size-title3-lg,
				lg: $font-size-title3-md,
				md: fs(20px),
				sm: fs(20px),
			));

			@include media-breakpoint-down(sm) {
				font-weight: $font-weight-normal;
			}
		}

		p {
			line-height: 1.4;
		}

		@include media-breakpoint-down(sm) {
			padding-bottom: $grid-gutter-width;

			.know-more {
				margin-top: 20px;
			}
		}
	}

	@include media-breakpoint-down(md) {
		margin: $grid-gutter-width 0;
	}
}
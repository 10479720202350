.tabs {
	margin: $marginHeight 0;

	.nav {
		button {
			cursor: pointer;
			width: 100%;
			text-align: left;
			border: solid $color-background-secondary;
			border-width: 2.5px 5px;
			background: $color-background-main;
			padding: 20px;

			font-weight: $font-weight-medium;
			transition: color 0.3s, background 0.3s, border 0.3s;

			&:focus {
				outline: none;
			}

			&:first-of-type {
				border-top-width: 5px;
			}
			&:last-of-type {
				border-bottom-width: 5px;
			}

			&.active {
				color: $white;
				background-color: $color-accent-primary_hover;
				border-color: $color-accent-primary_hover;

				&:hover {
					color: $white;
					background-color: $color-accent-primary_hover;
					border-color: $color-accent-primary_hover;
				}
			}

			&:hover {
				background: $color-background-secondary;
			}
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.content {
		min-height: 300px;
		overflow: hidden;

		.text {
			position: absolute;
			opacity: 0;

			@include media-breakpoint-down(sm) {
				position: relative;
				opacity: 1;

				border: solid $color-background-secondary;
				border-width: 2.5px 5px;

				&:first-of-type {
					border-top-width: 5px;
				}
				&:last-of-type {
					border-bottom-width: 5px;
				}

				.collapse-title {
					margin-bottom: 0px;
					font-weight: $font-weight-medium;
					font-size: fs(16px);
					letter-spacing: normal;
					padding-bottom: 0;
					padding: $grid-gutter-width/2;

					transition: margin 0.3s;
					
					&.open {
						background: $color-accent-primary_hover;
						color: $color-text-secondary;
						margin-bottom: $grid-gutter-width/2;

						@include media-breakpoint-down(sm) {
							~ .collapse-details {
								margin-bottom: $grid-gutter-width/2;
							}
						}
					}
				}

				.collapse-details {
					padding: 0 $grid-gutter-width/2;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			min-height: auto;
			padding: 0 $grid-gutter-width/2;
		}
	}

	@include media-breakpoint-down(sm) {
		margin: $marginHeightMobile 0;
	}
}
.half-n-half {
	margin: $marginHeight 0;

	.row {
		align-items: flex-start;

		&.hasbg {
			align-items: stretch;

			.right-side {
				.inner-block {
					background-color: $gray97;
					display: flex;
					height: 100%;
					align-items: center;
				}
			}
		}
	}

	.right-side, .left-side {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;

		[class^=button] {
			align-self: flex-start;
		}
	}

	.right-side {
		padding-left: 0px;

		.inner-block {
			width: 100%;
			align-items: flex-start;
	
			img {
				width: 100%;
			}
		}

		@include media-breakpoint-down(md) {
			padding-left: $grid-gutter-width/2;
		}
	}

	.left-side {
		padding-right: 0px;

		.inner-block {
			padding-right: calc(1/12 * 100% + #{$grid-gutter-width/2});
		
			&.hasbg {
				padding: 60px;
			}

			@include media-breakpoint-down(md) {
				padding: $grid-gutter-width;
				background-color: $color-background-secondary;
			}
			
			@include media-breakpoint-down(sm) {
				padding: 50px $grid-gutter-width;

				&.hasbg {
					padding: $grid-gutter-width;
				}
			}
			
			@include media-breakpoint-down(xs) {
				padding: $marginHeightMobile/2 $grid-gutter-width/2;

				&.hasbg {
					padding: $marginHeightMobile $grid-gutter-width/2;
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding-right: $grid-gutter-width/2;
		}
	}

	@include media-breakpoint-down(md) {
		.inverted-mobile {
			flex-direction: column-reverse;
		}
	}

	@include media-breakpoint-down(xs) {
		margin: $marginHeightMobile 0;
	}
}
.team {
	margin: $marginHeight 0;

	.team-member {
		position: relative;
		overflow: hidden;
	
		&:before {
			content: '';
			position: relative;
			display: block;
			padding-top: 100%;
		}
		
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	
		.infos {
			position: absolute;
				top: 0;
				left: 0;
			display: flex;
			width: 100%;
			height: 100%;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			padding: 30px;
	
			color: $white;
			font-size: 18px;
			font-weight: $font-weight-light;
			line-height: 1.3;
	
			transform: translate(0, 100%);
			transition: transform 0.3s ease;

			@include media-breakpoint-down(lg) {
				padding: 20px;
			}
	
			.name {
				font-weight: $font-weight-normal;
				font-size: 20px;
			}

			.title {
				font-size: fs(16px);
			}
	
			a {
				color: $white;
				text-decoration: none;
			}
	
			.linkedin {
				font-size: 20px;
			}
	
			// Pour Edge :(
			.phone a,
			.phone a:hover{
				font-weight: $font-weight-normal;
				text-decoration: none;
			}
	
			// Pour IE :(
			.name,
			.title,
			.phone,
			.icons{
				max-width: 100%;
			}
	
			background: linear-gradient(180deg, rgba($color-accent-primary, 0.3), rgba($color-accent-primary, 1));
		}
	
		.plus {
			position: absolute;
				bottom: 20px;
				right: 20px;
			opacity: 1;
	
			width: 50px;
			height: 50px;
			border-radius: 100%;
			background: rgba($color-accent-primary, 0.25);
	
			transition: opacity 0.3s ease;
			z-index: 1;
	
			&:after, &:before {
				content: '';
				position: absolute;
					top: 50%;
					left: 50%;
				width: 20px;
				height: 2px;
				background: $white;
				transform: translate(-50%, -50%);
			}
	
			&:after {
				transform: translate(-50%, -50%) rotate(90deg);
			}
		}
	
		&:hover {
			.infos {
				transform: translate(0, 0);
			}
	
			.plus {
				opacity: 0;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		margin: $marginHeightMobile $grid-gutter-width/2;
	}
}
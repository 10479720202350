.block-ctas{
	margin-top: 30px;
}

.block-cta {
	margin-bottom: $grid-gutter-width;
	// margin-top: $grid-gutter-width;
	text-align: center;
	display: flex;
	align-items: stretch;

	.bg {
		position: relative;
		@extend %title3;
		font-weight: $font-weight-light;
		text-decoration: none;
		transition: background-color 0.3s ease;
		
		display: block;
		max-width: 100%;
		border: 1px solid $color-accent-primary;
		margin: 0;
		padding: 60px 60px 140px 60px;
		flex: 0 0 100%;
		color: $white;
		background: $color-accent-primary;

		.plus {
			position: absolute;
				bottom: 60px;
				left: 50%;
			transform: translate(-50%, 0);
			width: 50px;
			height: 50px;
			background: $color-accent-primary_hover;
			border-radius: 100%;

			&:after,
			&:before {
				content: '';
				position: absolute;
					top: 50%;
					left: 50%;
				transform: translate(-50%, -50%);
				width: 20px;
				height: 2px;
				background: $white;
			}

			&:after {
				transform: translate(-50%, -50%) rotate(90deg);
				transform-origin: center center;
			}
		}

		&:hover, &:active, &:focus {
			text-decoration: none;
	
			color: $color-accent-primary;
			background: $white;
		}
	}
}
@import '../assets/fontello/css/fontello-codes.css';

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../assets/fonts/DINNextLTPro-Bold.eot');
    src: url('../assets/fonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/DINNextLTPro-Bold.woff2') format('woff2'),
        url('../assets/fonts/DINNextLTPro-Bold.woff') format('woff'),
        url('../assets/fonts/DINNextLTPro-Bold.svg#DINNextLTPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../assets/fonts/DINNextLTPro-Medium.eot');
    src: url('../assets/fonts/DINNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/DINNextLTPro-Medium.woff2') format('woff2'),
        url('../assets/fonts/DINNextLTPro-Medium.woff') format('woff'),
        url('../assets/fonts/DINNextLTPro-Medium.svg#DINNextLTPro-Bold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../assets/fonts/DINNextLTPro-Regular.eot');
    src: url('../assets/fonts/DINNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/DINNextLTPro-Regular.woff2') format('woff2'),
        url('../assets/fonts/DINNextLTPro-Regular.woff') format('woff'),
        url('../assets/fonts/DINNextLTPro-Regular.svg#DINNextLTPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../assets/fonts/DINNextLTPro-Light.eot');
    src: url('../assets/fonts/DINNextLTPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/DINNextLTPro-Light.woff2') format('woff2'),
        url('../assets/fonts/DINNextLTPro-Light.woff') format('woff'),
        url('../assets/fonts/DINNextLTPro-Light.svg#DINNextLTPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
	font-family: 'fontello';
	src: url('../../assets/fontello/font/fontello.eot');
	src: url('../../assets/fontello/font/fontello.eot#iefix') format('embedded-opentype'),
			url('../../assets/fontello/font/fontello.woff2') format('woff2'),
			url('../../assets/fontello/font/fontello.woff') format('woff'),
			url('../../assets/fontello/font/fontello.ttf') format('truetype'),
			url('../../assets/fontello/font/fontello.svg#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
@font-face {
	font-family: 'fontello';
	src: url('../font/fontello.svg?13559335#fontello') format('svg');
}
}
*/
@mixin fontello() {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: .2em;

	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

[class^="icon-"]:before, [class*=" icon-"]:before {
	@include fontello();
}
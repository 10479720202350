
// Website variables;
$grid-gutter-width: 30px;

// Containers variables
$container-xl: map-get($container-max-widths, xl);
$container-lg: map-get($container-max-widths, lg);
$container-md: map-get($container-max-widths, md);
$container-sm: map-get($container-max-widths, sm);

$menuHeight: 60px;
$menuTotalHeight: 80px;

$marginHeight: 60px;
$marginHeightMobile: 40px;

//===================================================================
// Colors
//===================================================================
$black: #000;
$white: #fff;

$gray8: #181818;
$gray97: #f7f7f7;
$grayF4: #F4F4F4;

$blue13: #192A5E;
$blue19: #1e2b5b;
$blue34: #34CEF4;
$blue74: #5ac5ed;
$blue77: #67cdf0;

$red44: #c42137;

$gold72: #e1a339;

$color-text-primary: $black;
$color-text-secondary: $white;

$color-accent-primary: $blue34;
$color-accent-primary_hover: $blue13;

$color-background-main: $white;
$color-background-secondary: $grayF4;
$color-background-dark: $blue77;
$color-background-darker: $blue74;
$color-background-darkest: $blue19;

$color-input-border: rgba($color-text-secondary, 0.3);
$color-input-placeholder: $color-text-secondary;
$color-input-error: $red44;
$color-input-error-border: $red44;

$slideHeight: 335px;


//===================================================================
// Fonts
//===================================================================

$font-family-title: 'DIN Next LT Pro', sans-serif;
$font-family-text: 'DIN Next LT Pro', sans-serif;

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

$font-size-title1-xl: fs(36px);
$font-size-title1-lg: fs(36px);
$font-size-title1-md: fs(36px);
$font-size-title1-sm: fs(36px * 0.8);
$font-size-title1-xs: fs(36px * 0.6);

$font-size-title2-xl: fs(30px);
$font-size-title2-lg: fs(30px);
$font-size-title2-md: fs(30px * 0.8);
$font-size-title2-sm: fs(30px * 0.8);
$font-size-title2-xs: fs(30px * 0.7);

$font-size-title3-xl: fs(26px);
$font-size-title3-lg: fs(26px);
$font-size-title3-md: fs(26px * 0.8);
$font-size-title3-sm: fs(26px * 0.8);
$font-size-title3-xs: fs(26px * 0.7);

$font-size-title4-xl: fs(16px);
$font-size-title4-lg: fs(16px);
$font-size-title4-md: fs(16px * 0.8);
$font-size-title4-sm: fs(16px * 0.8);
$font-size-title4-xs: fs(16px * 0.8);


$font-size-text-xl: fs(16px);
$font-size-text-lg: fs(16px);
$font-size-text-md: fs(16px);
$font-size-text-sm: fs(16px);
$font-size-text-xs: fs(16px);

$font-size-blockquote-xl: fs(22px);
$font-size-blockquote-lg: fs(22px);
$font-size-blockquote-md: fs(20px);
$font-size-blockquote-sm: fs(16px);
$font-size-blockquote-xs: fs(16px);

$font-size-text-small-lg: fs(14px);
$font-size-text-small-md: fs(14px);
$font-size-text-small-sm: fs(14px);
$font-size-text-small-xs: fs(14px);

//===================================================================
// Forms
//===================================================================


$input-field-height: 50px;
$input-field-border-color: #969696;
$input-field-padding: 15px;
$input-field-font-size: fs(14px);

$input-checkbox-font-size: fs(11px);
$input-checkbox-check-color: #fff;
.services {
	margin: $marginHeight 0;

	&.reverse {
		.service-block {
			text-align: left;
			border: 5px solid $color-background-secondary;

			.icon {
				margin: 0;
			}

			@include media-breakpoint-down(sm) {
				flex-wrap: wrap;

				.icon {
					margin-right: 20px;
				}

				.text {
					width: calc(100% - 85px);
					flex: 0 0 calc(100% - 85px);
					margin: 10px 0;

					.desc ul {
						margin-bottom: 0;
					}
				}

				[class^=button] {
					margin-top: 0;
				}
			}

			@include media-breakpoint-down(xs) {
				[class^=button] {
					width: 100%;
					flex: 0 0 100%;
				}
			}
		}
	}

	&:not([class*=reverse]) {
		.link-overlay {
			&:hover {
				~ .text {
					.desc {
						transform: translate(0, -5px);
					}

					.title {
						transform: translate(0, -10px);
					}
				}

				~ .icon {
					transform: translate(0, -15px);

					img {
						transform: translateZ(0);
					}
				}

				~ [class^=button] {
					background-color: $color-accent-primary_hover;
					transform: scale(1.08, 1.08);
				}
			}
		}

		.icon img {
			margin: 0 auto;
		}

		.service-block {
			background-color: $color-background-secondary;
			color: $color-accent-primary_hover;

			[class^=button] {
				background-color: darken($color-background-secondary, 10%);
			}

			@include media-breakpoint-down(sm) {
				[class^=button] {
					position: absolute;
						bottom: 10px;
						right: 10px;

					width: 26px;
					height: 26px;

					&:after {
						width: 27px;
						height: 27px;
						font-size: fs(18px);
					}
				}
			}
		}
	}

	.link-overlay {
		@include linkOverlay;
	}

	.service-title {
		text-align: center;
		margin-bottom: $grid-gutter-width;

		h1 {
			margin-bottom: 0;
		}

		@include media-breakpoint-down(xs) {
			margin-bottom: 20px;
		}
	}

	.service-button {
		text-align: center;

		[class^=button] {
			margin-top: 0;

			@include media-breakpoint-down(sm) {
				margin-top: $marginHeightMobile/2;
			}
		}
	}

	.services-blocks {
		[class^=col] {
			margin-bottom: $grid-gutter-width;

			@include media-breakpoint-down(sm) {
				margin-bottom: $grid-gutter-width/2;

				&:last-of-type() {
					margin-bottom: 0;
				}
			}
		}
	}

	.service-block {
		position: relative;
		padding: $grid-gutter-width;
		height: 100%;
		text-align: center;
		transform: translateZ(0);

		.desc, .title, .icon {
			transition: transform 0.3s;
		}

		.icon {
			margin: 0 auto;
			margin-bottom: $grid-gutter-width/2;

			img {
				display: block;
			}
		}

		.desc {
			li {
				margin-bottom: 10px;
			}
		}

		h1 {
			font-size: fs(18px);
			font-weight: $font-weight-medium;
			text-transform: uppercase;
		}

		.text {
			margin-bottom: $grid-gutter-width/2;
		}

		@include media-breakpoint-down(sm) {
			display: flex;
			flex-direction: row;
			padding: $marginHeightMobile/2;
			text-align: left;

			.icon {
				width: 98px;
				height: 98px;
				flex: 0 0 98px;
				margin: 0 20px 0 0;
			}

			.text {
				margin-bottom: 26px;

				.title {
					font-size: fs(14px);
				}
				.desc {
					font-size: fs(12px);
				}
			}
		}
	}

	@include media-breakpoint-down(xs) {
		margin: $marginHeightMobile 0;
	}
}

.publications {
	margin: $marginHeight 0;

	.publications-type-wrapper {
		margin-bottom: 60px;

		.publication-wrapper {
			border-bottom: 2px solid $gray97;
		}

		@include media-breakpoint-down(sm) {
			.publication-title {
				margin: 0;

			}
		}
	}

	.publication-type {
		@extend %title2;
		margin-bottom: 40px;
		padding-bottom: 10px;
		border-bottom: 1px solid $color-text-primary;

		@include media-breakpoint-down(sm) {
			margin: 0 $grid-gutter-width/2 $grid-gutter-width/2 $grid-gutter-width/2;
		}
	}

	.publication-category-control {
		position: relative;
		padding: 24px 0 20px 0;
		margin-bottom: 0;
		
		font-size: fs(20px);
		font-weight: $font-weight-medium;
		letter-spacing: normal;
		transition: color 0.3s;
	
		&:after{
			content: '\e801';
			font: 1rem 'fontello', sans-serif;

			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%) rotate(90deg);

			transition: transform 0.3s ease, color 0.3s;
		}

		&:hover {
			cursor: pointer;
			color: $color-accent-primary;

			&:after {
				color: $color-accent-primary;
			}
		}

		&.open {
			&:after {
				transform: translateY(-50%) rotate(270deg);
			}
		}
	}

	.publication-list {
		display: none;
		padding: 0 0 20px 0px;
	}

	.publication {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		h1,
		h2,
		h3,
		h4 {
			color: $blue19;
			color: $blue19;
			margin-bottom: 0px;
		}

		p {
			line-height: 28px;
		}
	}

	.read-more {
		margin-top: 10px;
	}
}